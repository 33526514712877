<template>
  <div>
    <ManagePartners />
  </div>
</template>
<script>
import ManagePartners from "@/components/Partners/ManagePartners";
export default {
  components: {
    ManagePartners,
  },
  created() {
  },
};
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '@/views/Homepage.vue'
import login from '../views/Login.vue'
import forgotPassword from '@/views/forgotPassword.vue'
import forgotPasswordSuccess from '@/views/forgotPasswordSuccess.vue'


Vue.use(VueRouter)

const routes = [{

    path: '/',
    name: 'login',
    component: login
},
{ path: '/forgotpassword', name: 'forgot', component: forgotPassword },
{ path: '/forgotPasswordSuccess', name: 'forgotSuccess', component: forgotPasswordSuccess },
{
    path: '/printpdf',
    name: 'printpdf',
    component: () =>
        import('@/views/printpdf.vue')
},
{
    path: '/ExportVat',
    name: 'ExportVat',
    component: () =>
        import('@/views/ExportExcel/ExportVat.vue')
},
{
    path: '/invc',
    name: 'invc',
    component: () =>
        import('@/views/ExportExcel/ExportInvoiceeee.vue')
},
{
    path: '/Home',
    component: Homepage,
    children: [
        // {
        //     path: '/ManageCategory',
        //     name: 'ManageCategory',
        //     component: () =>
        //         import('@/views/ManageCategory')
        // },
        // {
        //     path: '/CreateCategory',
        //     name: 'CreateCategory',
        //     component: () =>
        //         import('@/components/Category/CreateCategory.vue')
        // },
        // {
        //     path: '/EditCategory',
        //     name: 'EditCategory',
        //     component: () =>
        //         import('@/components/Category/EditCategory.vue')
        // },
        // {
        //     path: '/ViewCategory',
        //     name: 'ViewCategory',
        //     component: () =>
        //         import('@/components/Category/ViewCategory.vue')
        // },
        // {
        //     path: '/ManageType',
        //     name: 'ManageType',
        //     component: () =>
        //         import('@/views/ManageType')
        // },
        // {
        //     path: '/CreateType',
        //     name: 'CreateType',
        //     component: () =>
        //         import('@/components/Type/CreateType.vue')
        // },
        // {
        //     path: '/EditType',
        //     name: 'EditType',
        //     component: () =>
        //         import('@/components/Type/EditType.vue')
        // },
        // {
        //     path: '/ViewType',
        //     name: 'ViewType',
        //     component: () =>
        //         import('@/components/Type/ViewType.vue')
        // },
        // {
        //     path: '/ManageBrand',
        //     name: 'ManageBrand',
        //     component: () =>
        //         import('@/views/ManageBrand')
        // },
        // {
        //     path: '/CreateBrand',
        //     name: 'CreateBrand',
        //     component: () =>
        //         import('@/components/Brand/CreateBrand.vue')
        // },
        // {
        //     path: '/EditBrand',
        //     name: 'EditBrand',
        //     component: () =>
        //         import('@/components/Brand/EditBrand.vue')
        // },
        // {
        //     path: '/ViewBrand',
        //     name: 'ViewBrand',
        //     component: () =>
        //         import('@/components/Brand/ViewBrand.vue')
        // },
        {
            path: '/ManageBanners',
            name: 'ManageBanners',
            component: () =>
                import('@/views/ManageBanners')
        },
        {
            path: '/CreateBanners',
            name: 'CreateBanners',
            component: () =>
                import('@/components/Banners/CreateBanners.vue')
        },
        {
            path: '/EditBanners',
            name: 'EditBanners',
            component: () =>
                import('@/components/Banners/EditBanners.vue')
        },
        {
            path: '/ViewBanners',
            name: 'ViewBanners',
            component: () =>
                import('@/components/Banners/ViewBanners.vue')
        },
        {
            path: '/ManageBanners_1',
            name: 'ManageBanners_1',
            component: () =>
                import('@/views/ManageBanners_1')
        },
        {
            path: '/CreateBanners_1',
            name: 'CreateBanners_1',
            component: () =>
                import('@/components/Banners_1/CreateBanners_1.vue')
        },
        {
            path: '/EditBanners_1',
            name: 'EditBanners_1',
            component: () =>
                import('@/components/Banners_1/EditBanners_1.vue')
        },
        {
            path: '/ViewBanners_1',
            name: 'ViewBanners_1',
            component: () =>
                import('@/components/Banners_1/ViewBanners_1.vue')
        },
        {
            path: '/ManageBanners_2',
            name: 'ManageBanners_2',
            component: () =>
                import('@/views/ManageBanners_2')
        },
        {
            path: '/CreateBanners_2',
            name: 'CreateBanners_2',
            component: () =>
                import('@/components/Banners_2/CreateBanners_2.vue')
        },
        {
            path: '/EditBanners_2',
            name: 'EditBanners_2',
            component: () =>
                import('@/components/Banners_2/EditBanners_2.vue')
        },
        {
            path: '/ViewBanners_2',
            name: 'ViewBanners_2',
            component: () =>
                import('@/components/Banners_2/ViewBanners_2.vue')
        },
        {
            path: '/ManageBanners_3',
            name: 'ManageBanners_3',
            component: () =>
                import('@/views/ManageBanners_3')
        },
        {
            path: '/CreateBanners_3',
            name: 'CreateBanners_3',
            component: () =>
                import('@/components/Banners_3/CreateBanners_3.vue')
        },
        {
            path: '/EditBanners_3',
            name: 'EditBanners_3',
            component: () =>
                import('@/components/Banners_3/EditBanners_3.vue')
        },
        {
            path: '/ViewBanners_3',
            name: 'ViewBanners_3',
            component: () =>
                import('@/components/Banners_3/ViewBanners_3.vue')
        },
        {
            path: '/ManageBanners_4',
            name: 'ManageBanners_4',
            component: () =>
                import('@/views/ManageBanners_4')
        },
        {
            path: '/CreateBanners_4',
            name: 'CreateBanners_4',
            component: () =>
                import('@/components/Banners_4/CreateBanners_4.vue')
        },
        {
            path: '/EditBanners_4',
            name: 'EditBanners_4',
            component: () =>
                import('@/components/Banners_4/EditBanners_4.vue')
        },
        {
            path: '/ViewBanners_4',
            name: 'ViewBanners_4',
            component: () =>
                import('@/components/Banners_4/ViewBanners_4.vue')
        },
        {
            path: '/ManageBanners_5',
            name: 'ManageBanners_5',
            component: () =>
                import('@/views/ManageBanners_5')
        },
        {
            path: '/CreateBanners_5',
            name: 'CreateBanners_5',
            component: () =>
                import('@/components/Banners_5/CreateBanners_5.vue')
        },
        {
            path: '/EditBanners_5',
            name: 'EditBanners_5',
            component: () =>
                import('@/components/Banners_5/EditBanners_5.vue')
        },
        {
            path: '/ViewBanners_5',
            name: 'ViewBanners_5',
            component: () =>
                import('@/components/Banners_5/ViewBanners_5.vue')
        },
        {
            path: '/ViewBanners_8',
            name: 'ViewBanners_8',
            component: () =>
                import('@/components/Banners_8/ViewBanners_8.vue')
        },
        {
            path: '/ManageBanners_8',
            name: 'ManageBanners_8',
            component: () =>
                import('@/views/ManageBanners_8')
        },
        {
            path: '/CreateBanners_8',
            name: 'CreateBanners_8',
            component: () =>
                import('@/components/Banners_8/CreateBanners_8.vue')
        },
        {
            path: '/EditBanners_8',
            name: 'EditBanners_8',
            component: () =>
                import('@/components/Banners_8/EditBanners_8.vue')
        },
        {
            path: '/ViewBanners_8',
            name: 'ViewBanners_8',
            component: () =>
                import('@/components/Banners_8/ViewBanners_8.vue')
        },

        {
            path: '/ManageBanners_9',
            name: 'ManageBanners_9',
            component: () =>
                import('@/views/ManageBanners_9')
        },
        {
            path: '/CreateBanners_9',
            name: 'CreateBanners_9',
            component: () =>
                import('@/components/Banners_9/CreateBanners_9.vue')
        },
        {
            path: '/EditBanners_9',
            name: 'EditBanners_9',
            component: () =>
                import('@/components/Banners_9/EditBanners_9.vue')
        },
        {
            path: '/ViewBanners_9',
            name: 'ViewBanners_9',
            component: () =>
                import('@/components/Banners_9/ViewBanners_9.vue')
        },

        {
            path: '/ManageBanners_10',
            name: 'ManageBanners_10',
            component: () =>
                import('@/views/ManageBanners_10')
        },
        {
            path: '/CreateBanners_10',
            name: 'CreateBanners_10',
            component: () =>
                import('@/components/Banners_10/CreateBanners_10.vue')
        },
        {
            path: '/EditBanners_10',
            name: 'EditBanners_10',
            component: () =>
                import('@/components/Banners_10/EditBanners_10.vue')
        },
        {
            path: '/ViewBanners_10',
            name: 'ViewBanners_10',
            component: () =>
                import('@/components/Banners_10/ViewBanners_10.vue')
        },
        {
            path: '/ManagePartners',
            name: 'ManagePartners',
            component: () =>
                import('@/views/ManagePartners')
        },
        {
            path: '/CreatePartners',
            name: 'CreatePartners',
            component: () =>
                import('@/components/Partners/CreatePartners.vue')
        },
        {
            path: '/EditPartners',
            name: 'EditPartners',
            component: () =>
                import('@/components/Partners/EditPartners.vue')
        },
        {
            path: '/ViewPartners',
            name: 'ViewPartners',
            component: () =>
                import('@/components/Partners/ViewPartners.vue')
        },
        {
            path: '/formlist',
            name: 'formlist',
            component: () =>
                import('@/views/FormList.vue')
        },
        {
            path: '/reportlist',
            name: 'reportlist',
            component: () =>
                import('@/views/ReportList.vue')
        },
        {
            path: '/Table1',
            name: 'Table1',
            component: () =>
                import('@/views/Table1.vue')
        },
        {
            path: '/Table2',
            name: 'Table2',
            component: () =>
                import('@/views/Table2.vue')
        },
        {
            path: '/Table3',
            name: 'Table3',
            component: () =>
                import('@/views/Table3.vue')
        },
        {
            path: '/Table4',
            name: 'Table4',
            component: () =>
                import('@/views/Table4.vue')
        },

        // {
        //     path: '/CreateProduct',
        //     name: 'CreateProduct',
        //     component: () =>
        //         import('@/components/Product/CreateProduct.vue')
        // },
        // {
        //     path: '/ManageProduct',
        //     name: 'ManageProduct',
        //     component: () =>
        //         import('@/views/ManageProduct')
        // },
        // {
        //     path: '/CreateProduct',
        //     name: 'CreateProduct',
        //     component: () =>
        //         import('@/components/Product/CreateProduct.vue')
        // },
        // {
        //     path: '/viewProduct',
        //     name: 'viewProduct',
        //     component: () =>
        //         import('@/components/Product/viewProduct.vue')
        // },
        // {
        //     path: '/EditProduct',
        //     name: 'EditProduct',
        //     component: () =>
        //         import('@/components/Product/EditProduct.vue')
        // },
        {
            path: '/CreateUser',
            name: 'CreateUser',
            component: () =>
                import('@/components/User/CreateUser')
        },
        {
            path: '/ManageUser',
            name: 'ManageUser',
            component: () =>
                import('@/views/ManageUser')
        },
        {
            path: '/viewUser',
            name: 'viewUser',
            component: () =>
                import('@/components/User/viewUser.vue')
        }, 
        {
            path: '/UpdateUser',
            name: 'UpdateUser',
            component: () =>
                import('@/components/User/UpdateUser.vue')
        }, 
        // {
        //     path: '/ManageOrder',
        //     name: 'ManageOrder',
        //     component: () =>
        //         import('@/views/ManageOrder')
        // },
        // {
        //     path: '/viewOrder',
        //     name: 'viewOrder',
        //     component: () =>
        //         import('@/components/Order/viewOrder.vue')
        // },
        // {
        //     path: '/EditOrder',
        //     name: 'EditOrder',
        //     component: () =>
        //         import('@/components/Order/EditOrder.vue')
        // },
        // {
        //     path: '/ManageCompany',
        //     name: 'ManageCompany',
        //     component: () =>
        //         import('@/views/ManageCompany')
        // },
        // {
        //     path: '/CreateCompany',
        //     name: 'CreateCompany',
        //     component: () =>
        //         import('@/components/Company/CreateCompany.vue')
        // },
        // {
        //     path: '/EditCompany',
        //     name: 'EditCompany',
        //     component: () =>
        //         import('@/components/Company/EditCompany.vue')
        // },
        // {
        //     path: '/ViewCompany',
        //     name: 'ViewCompany',
        //     component: () =>
        //         import('@/components/Company/ViewCompany.vue')
        // },
        // {
        //     path: '/Dashboard',
        //     name: 'Dashboard',
        //     component: () =>
        //         import('@/views/Dashboard')
        // },
        // {
        //     path: '/ManageNews',
        //     name: 'ManageNews',
        //     component: () =>
        //         import('@/views/ManageNews')
        // },
        // {
        //     path: '/CreateNews',
        //     name: 'CreateNews',
        //     component: () =>
        //         import('@/components/News/CreateNews.vue')
        // },
        // {
        //     path: '/ViewNews',
        //     name: 'ViewNews',
        //     component: () =>
        //         import('@/components/News/ViewNews.vue')
        // },
        // {
        //     path: '/EditNews',
        //     name: 'EditNews',
        //     component: () =>
        //         import('@/components/News/EditNews.vue')
        // },
        // {
        //     path: '/ManageCoupon',
        //     name: 'ManageCoupon',
        //     component: () =>
        //         import('@/views/ManageCoupon')
        // },
        // {
        //     path: '/CreateCoupon',
        //     name: 'CreateCoupon',
        //     component: () =>
        //         import('@/components/Coupon/CreateCoupon.vue')
        // },
        // {
        //     path: '/ViewCoupon',
        //     name: 'ViewCoupon',
        //     component: () =>
        //         import('@/components/Coupon/ViewCoupon.vue')
        // },
        // {
        //     path: '/EditCoupon',
        //     name: 'EditCoupon',
        //     component: () =>
        //         import('@/components/Coupon/EditCoupon.vue')
        // },
        // {
        //     path: '/ManageVoucher',
        //     name: 'ManageVoucher',
        //     component: () =>
        //         import('@/views/ManageVoucher')
        // },
        // {
        //     path: '/CreateVoucher',
        //     name: 'CreateVoucher',
        //     component: () =>
        //         import('@/components/Voucher/CreateVoucher.vue')
        // },
        // {
        //     path: '/ViewVoucher',
        //     name: 'ViewVoucher',
        //     component: () =>
        //         import('@/components/Voucher/ViewVoucher.vue')
        // },
        // {
        //     path: '/EditVoucher',
        //     name: 'EditVoucher',
        //     component: () =>
        //         import('@/components/Voucher/EditVoucher.vue')
        // },
        // {
        //     path: '/ManageAnnouncement',
        //     name: 'ManageAnnouncement',
        //     component: () =>
        //         import('@/views/ManageAnnouncement')
        // },
        // {
        //     path: '/CreateAnnouncement',
        //     name: 'CreateAnnouncement',
        //     component: () =>
        //         import('@/components/Announcement/CreateAnnouncement.vue')
        // },
        // {
        //     path: '/ViewAnnouncement',
        //     name: 'ViewAnnouncement',
        //     component: () =>
        //         import('@/components/Announcement/ViewAnnouncement.vue')
        // },
        // {
        //     path: '/EditAnnouncement',
        //     name: 'EditAnnouncement',
        //     component: () =>
        //         import('@/components/Announcement/EditAnnouncement.vue')
        // },
        // {
        //     path: '/Report1',
        //     name: 'Report1',
        //     component: () =>
        //         import('@/components/Report/Report1.vue')
        // },
        // {
        //     path: '/Report2',
        //     name: 'Report2',
        //     component: () =>
        //         import('@/components/Report/Report2.vue')
        // },
    ]
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
<template>
  <v-card class="pa-4">
    <v-row>
      <v-col cols="12">
        <GmapMap
          v-if="items.length > 0"
          :center="center"
          :zoom="15"
          map-style-id="roadmap"
          :options="mapOptions"
          style="width: 100%; height: 60vmin"
          ref="mapRef"
        >
          <GmapMarker
            v-for="(risk, index) of items"
            :key="risk.id"
            :position="risk.position"
            :clickable="true"
            :label="risk.label"
            @click="toggleInfoWindow(risk,index)"
            
          />
        <GmapInfoWindow
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen=false"
      >
        <div v-html="infoContent"></div>
      </GmapInfoWindow>

        </GmapMap>
      </v-col>
      <v-col cols="12">
        <h2 style="font-weight: 800; color: #577eba">
          สรุปจุดเสี่ยง
        </h2>
        <!-- <h2 style="color: #577eba">ข้อมูลการแจ้งเบาะแส</h2> -->
      </v-col>
    </v-row>

    <v-row class="d-flex justify-space-between">
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col class="text-right" cols="12" sm="4" md="4">
        <!-- <v-btn dark color="#577eba"><ExportTable1 :data="items" /></v-btn> -->
        <ExportTable1 :data="items" :formDate="date" :toDate="date1" />
      </v-col>
      <v-col class="text-right" cols="12" sm="12" md="12">
        <!-- <v-btn dark color="#577eba"><ExportTable1 :data="items" /></v-btn> -->
        <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มจุดเสี่ยง</v-btn
            >
      </v-col>
    </v-row>
    <br />
    <v-data-table
      class="mt-3 elevation-1"
      :headers="headers"
      :items="items"
      :search="search"
      :footer-props="{
        'items-per-page-options': [20, 30, 40, 50],
      }"
      :items-per-page="20"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ convertDate(item.createdAt) }}</span>
      </template>
      <template v-slot:[`item.updatedAt`]="{ item }">
        <span>{{ convertDate(item.updatedAt) }}</span>
      </template>
      <template v-slot:[`item.image_url`]="{ item }">
        <v-icon v-if="item.image_url" @click="goToPic(item.image_url)" class="mx-2"
            >mdi-image-outline</v-icon
          >
          <p v-else>-</p>
      </template>
      <template v-slot:[`item.position`]="{ item }">
        <v-row justify="center">
          <v-icon @click="onClickMarker(item.position)" class="mx-2"
            >mdi-map-marker</v-icon
          >
        </v-row>
      </template>
      <!-- <template v-slot:[`item.action`]="{ item }">
        <v-row justify="center">
          <v-icon @click="UpdateBanner(item)" class="mx-2"
            >mdi-text-box-search-outline</v-icon
          >
        </v-row>
      </template> -->
      <template v-slot:[`item.action`]="{ item }">
        <v-row justify="center">
          <v-icon @click="DeleteBanner(item)">mdi-delete</v-icon>
        </v-row>
      </template>
      <template v-slot:[`item.export`]="{ item }">
        <v-row justify="center">
          <v-icon @click="goToPrint(item.id)" class="mx-2"
            >mdi-text-box-outline</v-icon
          >
        </v-row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
import ExportTable1 from "@/views/ExportExcel/ExportTable1.vue";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";

import { saveAs } from "file-saver";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

export default {
  components: {
    Loading,
    ExportTable1,
  },
  data: () => ({
    marker: { position: { lat: 10, lng: 10 } },
    center: { lat: 13.7245601, lng: 100.4930261 },
    mapOptions: {
      disableDefaultUI: true,
    },
    infoContent: '',
        infoWindowPos: {
          lat: 0,
          lng: 0
        },
        infoWinOpen: false,
        currentMidx: null,
        //optional: offset infowindow so it visually sits nicely on top of our marker
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
    m_group: "",
    m_groupItems: [],
    m_area: "",
    m_areaItems: [],
    m_province: "",
    m_provinceItems: [],
    drugs: [],

    points: [],
    search: "",
    menu: false,
    menu1: false,
    chip1: "",
    chip2: "",
    chip3: "",
    chip4: "",
    chip5: "",
    chip6: "",
    chip7: "",
    chip8: "",

    date: new Date("2022-01-01").toISOString().substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    headers: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ชื่อสถานที่", value: "name", align: "center" },
      // { text: "ประเภทข้อมูล", value: "reportTypes.type_name", align: "center" },
      { text: "รายละเอียด", value: "descriptions", align: "center" },
      { text: "ภาพถ่าย", value: "image_url", align: "center" },
      { text: "ดูตำแหน่ง", value: "position", align: "center" },
      // { text: "วันที่ดำเนินการ", value: "updatedAt", align: "center" },
      // { text: "ผู้ดำเนินการ", value: "updateBy", align: "center" },
      // { text: "สถานะ", value: "status", align: "center" },
      { text: "ลบข้อมูล", value: "action", align: "center" },
      // { text: "ดาวน์โหลดบันทึกข้อความ", value: "export", align: "center" },
    ],
    items: [],
    type_name: "",
    whereReportDrug: "",
    status: "",
    itemtype: [
      { text: "ทั้งหมด", value: "" },
      { text: "ผู้ผลิตยาเสพติด", value: "ผู้ผลิตยาเสพติด" },
      { text: "ผู้ค้ารายย่อย", value: "ผู้ค้ารายย่อย" },
      { text: "ผู้เสพยาเสพติด", value: "ผู้เสพยาเสพติด" },
      { text: "ผู้ขายและเสพยาเสพติด", value: "ผู้ขายและเสพยาเสพติด" },
      { text: "แหล่งจำหน่าย", value: "แหล่งจำหน่าย" },
      { text: "แหล่งมั่วสุม", value: "แหล่งมั่วสุม" },
      { text: "ผู้ค้ารายใหญ่", value: "ผู้ค้ารายใหญ่" },
      { text: "ตามหมายจับ", value: "ตามหมายจับ" },
    ],
    itemstatus: [
      { text: "ทั้งหมด", value: "" },
      { text: "ยังไม่ได้ดำเนินการ", value: "ยังไม่ได้ดำเนินการ" },
      { text: "พบพฤติการณ์", value: "พบพฤติการณ์" },
      { text: "ไม่พบพฤติการณ์", value: "ไม่พบพฤติการณ์" },
      { text: "พิสูจน์ทราบไม่ได้", value: "พิสูจน์ทราบไม่ได้" },
      { text: "จับกุม", value: "จับกุม" },
      { text: "รายงานตัว/หยุดพฤติการณ์", value: "รายงานตัว/หยุดพฤติการณ์" },
      { text: "หลบหนีออกนอกพื้นพี่", value: "หลบหนีออกนอกพื้นพี่" },
      { text: "อยู่ระหว่างสืบสวนติดตาม", value: "อยู่ระหว่างสืบสวนติดตาม" },
    ],

    itemdrug: [
      {
        text: "ทั้งหมด",
        value: "",
      },
      {
        text: "ยาบ้า",
        value: "ยาบ้า",
      },
      {
        text: "ไอซ์",
        value: "ไอซ์",
      },
      {
        text: "ยาอี",
        value: "ยาอี",
      },
      {
        text: "กัญชา",
        value: "กัญชา",
      },
      {
        text: "ยาเค",
        value: "ยาเค",
      },
      {
        text: "กระท่อม",
        value: "กระท่อม",
      },
    ],
  }),
  created() {
    this.user = JSON.parse(
      Decode.decode(localStorage.getItem("userBebraveWeb"))
    );

    // this.getm_group();
    this.getAllData();
    console.log("date", this.date);
    console.log("date1", this.date1);
  },
  methods: {
    goToPic(url){
      window.open(url)
    },
    async DeleteBanner(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/riskPoints/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllData();
        }
      });
    },
    goToCreate() {
      this.$router.push("createbanners_2");
    },
    onClickMarker(position){
      this.center.lat = position.lat;
      this.center.lng = position.lng;
    },
    toggleInfoWindow(marker, idx) {
        // this.center.lat = marker.posioinlat;
        // this.center.lng = marker.posioin.lng;
        this.infoWindowPos = marker.position;
        this.infoContent = this.getInfoWindowContent(marker);


        //check if its the same marker that was selected if yes toggle
        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;
        }
      },

      getInfoWindowContent(marker) {
        return (`<div class="card">
          <div class="card-image">
            <figure class="image is-4by3">
              <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">${marker.name}</p>
                <p style="font-size: 0.8rem;">${marker.descriptions}</p>
              </div>
            </div>
          </div>
        </div>`);
      },
    
    async getm_group() {
      const m_groupItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs`
      );
      this.m_groupItems = m_groupItems.data.data;
      console.log("this.m_groupItems", this.m_groupItems);
    },
    async getm_area(val) {
      console.log("val", val);
      const m_areaItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs/findAreaByGroup?group=${this.m_group}`
      );
      this.m_areaItems = m_areaItems.data.data;
      console.log("this.m_areaItems", this.m_areaItems);
    },
    async getm_province(val) {
      console.log("val", val);
      const m_provinceItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs/findProvinceByArea?area=${this.m_area}`
      );
      this.m_provinceItems = m_provinceItems.data.data;
      console.log("this.m_provinceItems", this.m_provinceItems);
    },
    clear() {
      this.type_name = "";
      this.whereReportDrug = "";
      this.status = "";
      this.date = new Date("2022-01-01").toISOString().substr(0, 10);
      this.date1 = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.m_area = "";
      this.m_group = "";
      this.m_province = "";
      this.getAllData();
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async getAllData() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      let response = await this.axios.get(
        `${process.env.VUE_APP_API}/riskPoints`,
        auth
      );
      // localhost:8081/reportForms?type_name=แหล่งมั่วสุม&whereReportDrug=ยาไอซ์&status=ยังไม่ได้ดำเนินการ&fromDate=2022-01-27&toDate=2022-01-29
      console.log("riskPoints", response.data.data);

      // this.imgUrl = response.data.data.imgUrl;
      // for (let index = 0; index < response.data.data.length; index++) {
      //   // const risk = array[index];
      //   response.data.data[index].position = { position: { lat: (response.data.data[index].latitude * 1), lng: (response.data.data[index].longitude * 1) } };

      //   console.log( response.data.data[index].position)
      // }
      this.items = response.data.data;
      // console.log(items)
      for (let i in this.items) {
        this.items[i].number = parseInt(i) + 1;


      }

      // const responseLocations = await this.axios.get(
      //   `${process.env.VUE_APP_API}/reportForms/getAllLocations`
      // );
      // // localhost:8081/reportForms?type_name=แหล่งมั่วสุม&whereReportDrug=ยาไอซ์&status=ยังไม่ได้ดำเนินการ&fromDate=2022-01-27&toDate=2022-01-29
      // console.log("responseLocations", responseLocations.data.data);
      // this.points = responseLocations.data.data;

      // const responseDrugs = await this.axios.get(
      //   // `${process.env.VUE_APP_API}/reportForms/countByDrug?drug_name=${this.drug_name}&fromDate=2022-01-27&toDate=2022-01-27`,
      //   `${process.env.VUE_APP_API}/reportForms/countByDrug?drug_name=${this.whereReportDrug}&fromDate=${this.date}&toDate=${this.date1}&group=${this.m_group}&area=${this.m_area}&province=${this.m_province}`,

      //   // 'http://localhost:8081/types/'
      // );
      // console.log("getAllDrugs", responseDrugs.data.data);
      // this.drugs = responseDrugs.data.data;

      this.loading = false;
    },
    UpdateBanner(val) {
      console.log("val", val);
      localStorage.setItem("Bannerdata", Encode.encode(val));
      this.$router.push("formlist");
    },
    async goToPrint(reportId) {
      loadFile(
        "https://thai-drug-service-temp.yuzudigital.com/word/export_drug.docx",
        function(error, content) {
          if (error) {
            throw error;
          }
          const zip = new PizZip(content);
          const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
          });
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render({
            //case date
            station: "สถานี",
          });

          const out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          // Output the document using Data-URI
          saveAs(out, "บันทึกข้อความ.docx");
        }
      );
    },
  },
};
</script>

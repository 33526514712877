<template>
  <v-row align="center" justify="center" class="pa-4">
    <loading :active.sync="loading"></loading>
    <v-col>
      <h1 style="font-weight: 800; color: #577eba">
        แก้ไขข้อมูลศูนย์การเรียนรู้
      </h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">หัวข้อ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="learning_name"
              label="หัวข้อ"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">หมวดหมู่</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-select
              v-model="learning_type"
              :items="['คดีสำคัญต่างๆ', 'กฎหมายน่ารู้', 'ข้อมูลยาเสพติด']"
              label="หมวดหมู่"
              solo
              dense
              :rules="rules.required"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="learning_type == 'กฎหมายน่ารู้'">
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">เนื้อหา</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <quill-editor
              :content="content"
              :options="editorOption"
              @change="onEditorChange($event)"
              :rules="rules.required"
            />
          </v-col>
        </v-row>

        <v-row v-if="learning_type !== 'กฎหมายน่ารู้'">
          <v-col cols="12" md="3" sm="3">
            <span>แนบไฟล์</span>
            <span style="color: red">*</span>
            <br />
            <!-- <span style="color: red">ใช้นามสกุล .png ขนาด 300*300 </span> -->
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <!-- accept="image/png, image/jpeg, image/bmp" -->
            <v-file-input
              v-model="file"
              append-icon="mdi-file"
              filled
              show-size
              truncate-length="50"
              placeholder="แนบไฟล์"
              solo
              dense
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">สร้างโดย</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="create_by"
              label="สร้างโดย"
              solo
              dense
              :rules="rules.required"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="editable">
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">แก้ไขโดย</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="update_by"
              label="สร้างโดย"
              solo
              dense
              :rules="rules.required"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="primary">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import thoundsanseperate from "@/utils/thoundsanseperate.js";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      editable: true,
      learning_name: "",
      learning_type: "",
      learning_desc: "",
      learning_pdf: "",
      create_by: "",
      update_by: "",

      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      thoundsanseperate,
      file: [],
      activeFlag: true,
      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      img: "",
      loading: false,
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  async created() {
    this.user = JSON.parse(
      Decode.decode(localStorage.getItem("userBebraveWeb"))
    );
    console.log("userdata", this.user.user);
    let Bannerdata9 = JSON.parse(
      Decode.decode(localStorage.getItem("Bannerdata9"))
    );
    this.id = Bannerdata9.id;
    this.learning_name = Bannerdata9.learning_name;
    this.learning_type = Bannerdata9.learning_type;
    this.learning_desc = Bannerdata9.learning_desc;
    this.pic1URL = Bannerdata9.announce_pdf;
    this.create_by = Bannerdata9.create_by;

    // this.create_by = this.user.user.firstname + " " + this.user.user.lastname;
    this.update_by = this.user.user.firstname + " " + this.user.user.lastname;
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      console.log("file", file);
      // console.log(this.type);
    },
    async submitFile() {
      if (this.learning_type !== "กฎหมายน่ารู้") {
        let formData = new FormData();
        formData.append("uploadFile", this.file);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        // const formData = {
        //   uploadFile: this.file,
        // };
        this.uploaded = true;
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/files/formData`,
          formData
        );
        // .then(function () {
        console.log("SUCCESS!!");
        console.log("response", response);
        this.uploaded = false;
        if (response.data.response_status == "SUCCESS") {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "success",
            text: "อัพโหลดสำเร็จ",
          });
          this.pic1URL = response.data.data.path;
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "อัพโหลดไฟล์ไม่สำเร็จ",
          });
        }
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },

    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageBanners_9");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        if (this.file.length !== 0) {
          await this.submitFile();
        }
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userBebraveWeb"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          learning_name: this.learning_name,
          learning_type: this.learning_type,
          learning_desc: this.content,
          learning_pdf: this.pic1URL,
          create_by: this.create_by,
          update_by: "",
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/learnings/` + this.id,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขศูนย์การเรียนรู้สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageBanners_9");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
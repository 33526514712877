<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 style="font-weight: 800; color: #577eba">ข้อมูลผู้ใช้งาน</h1>
      </v-col>
      <!-- <v-col cols="12">
        <v-tabs color="basil">
          <v-tabs-slider color="#577eba"></v-tabs-slider>
          <v-tab
            v-for="item in tabitems"
            :key="item.key"
            @click="tab(item)"
            style="font-weight: 800; font-size: 24px"
          >
            <span>{{ item.text }}</span>
          </v-tab>
        </v-tabs>
      </v-col> -->
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary">เพิ่มผู้ใช้งาน</v-btn>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersCategory"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.activeFlag`]="{ item }">
              <span v-if="item.activeFlag">ใช้งาน</span>
              <span v-else>ไม่ใช้งาน</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon @click="viewUser(item)">mdi-eye</v-icon>
                <v-icon @click="UpdateUser(item)" class="mx-2"
                  >mdi-pencil</v-icon
                > -->
                <v-icon @click="DeleteUser(item)">mdi-delete</v-icon>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      headersCategory: [
        // activeFlag: true
        // createdAt: "2022-02-20T13:27:41.282Z"
        // id: 2
        // m_area: ""
        // m_group: "ปส.1"
        // m_province: ""
        // name: "donut admin"
        // tel: "0873576012"
        // updatedAt: "2022-02-20T13:27:41.282Z"
        // user_type: "99"
        { text: "ลำดับ", value: "count", align: "center" },
        ,
        { text: "ชื่อ", value: "full_name", align: "center" },
        { text: "หมายเลขโทรศัพท์", value: "tel", align: "center" },
        { text: "เพศ", value: "gender", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      tabs: "",
      tabitems: [
        { text: "ผู้ใช้งานทั่วไป", key: "ผู้ใช้งานทั่วไป" },
        { text: "แอดมิน", key: "แอดมิน" },
      ],
      items: [],
      search: "",
      count: 0,
    };
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    async getAllUsers() {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBebraveWeb"))
      );
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users`,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("users", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    async getAllAdmins() {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBebraveWeb"))
      );
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findAllAdmin`,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("users", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    viewUser(val) {
      localStorage.setItem("userdata", Encode.encode(val));
      //   console.log(val);
      this.$router.push("viewUser");
    },
    UpdateUser(val) {
      localStorage.setItem("userdata", Encode.encode(val));
      this.$router.push("UpdateUser");
    },
    async DeleteUser(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        // cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBebraveWeb"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/users/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllUsers();
        }
      });
    },
    goToCreate() {
      this.$router.push("createuser");
    },
    tab(val) {
      if (val.text == "แอดมิน") {
        this.getAllAdmins();
      }
      if (val.text == "ผู้ใช้งานทั่วไป") {
        this.getAllUsers();
      }
    },
  },
};
</script>
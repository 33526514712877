<template>
  <v-card class="pa-4">
    <v-row>
      <v-col cols="12">
        <h2 style="font-weight: 800; color: #577eba">
          สรุปการแจ้งเบาะแสยาเสพติด
        </h2>
        <h2 style="color: #577eba">ข้อมูลตามประเภทข้อมูล</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-autocomplete
          v-model="m_group"
          :items="m_groupItems"
          item-text="m_group"
          label="เลือก ปส."
          outlined
          dense
          hide-details="auto"
          @change="getm_area(m_group)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-autocomplete
          v-model="m_area"
          :items="m_areaItems"
          item-text="m_area"
          label="เลือก ภาค"
          outlined
          dense
          hide-details="auto"
          @change="getm_province(m_area)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-autocomplete
          v-model="m_province"
          :items="m_provinceItems"
          item-text="m_province"
          label="เลือก จังหวัด"
          outlined
          dense
          hide-details="auto"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-select
          v-model="type_name"
          item-text="text"
          item-value="value"
          :items="itemdrug"
          label="ประเภทยาเสพติด"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <!-- </v-row>
    <v-row> -->
      <v-col cols="12" sm="4" md="4">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              append-icon="mdi-calendar"
              label="วันที่เริ่มต้น"
              outlined
              dense
              hide-details="auto"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date1"
              append-icon="mdi-calendar"
              label="ถึงวันที่"
              outlined
              dense
              hide-details="auto"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-row class="mt-0 mr-1" justify="end">
        <v-col cols="12" sm="4" md="4" class="text-right">
          <v-btn @click="clear()">เคลียร์ </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="4" class="text-right">
          <v-btn dark color="#577eba" @click="getAllData()">ค้นหา </v-btn>
        </v-col>
      </v-row>
    </v-row>

    <v-row class="d-flex justify-space-between">
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col class="text-right" cols="12" sm="4" md="4">
        <ExportTable3 :data="items" :formDate="date" :toDate="date1" />
      </v-col>
    </v-row>

    <v-data-table class="mt-3 elevation-1" :headers="headers" :items="items">
      <template v-slot:[`item.action`]="{ item }">
        <v-row justify="center">
          <!-- <v-icon @click="ViewBanner(item)">mdi-eye</v-icon> -->
          <v-icon @click="UpdateBanner(item)" class="mx-2"
            >mdi-text-box-search-outline</v-icon
          >
          <!-- <v-icon @click="DeleteBanner(item)">mdi-delete</v-icon> -->
        </v-row>
      </template></v-data-table
    >
  </v-card>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import ExportTable3 from "@/views/ExportExcel/ExportTable3.vue";
export default {
  components: {
    Loading,
    ExportTable3,
  },
  data: () => ({
    m_group: "",
    m_groupItems: [],
    m_area: "",
    m_areaItems: [],
    m_province: "",
    m_provinceItems: [],
    menu: false,
    menu1: false,
    date: new Date("2022-01-01").toISOString().substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    headers: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ประเภทข้อมูล", value: "type_name", align: "center" },
      { text: "จำนวน(รายการ)", value: "count", align: "center" },
      // { text: "ข้อมูล", value: "action", align: "center" },
    ],
    items: [],
    type_name: "",
    itemdrug: [
      {
        text: "ทั้งหมด",
        value: "",
      },
      {
        text: "ผู้ผลิตยาเสพติด",
        value: "ผู้ผลิตยาเสพติด",
      },
      {
        text: "ผู้ค้ารายย่อย",
        value: "ผู้ค้ารายย่อย",
      },
      {
        text: "ผู้เสพยาเสพติด",
        value: "ผู้เสพยาเสพติด",
      },
      {
        text: "ผู้ขายและเสพยาเสพติด",
        value: "ผู้ขายและเสพยาเสพติด",
      },
      {
        text: "แหล่งจำหน่าย",
        value: "แหล่งจำหน่าย",
      },
      {
        text: "แหล่งมั่วสุม",
        value: "แหล่งมั่วสุม",
      },
      {
        text: "ผู้ค้ารายใหญ่",
        value: "ผู้ค้ารายใหญ่",
      },
      {
        text: "ตามหมายจับ",
        value: "ตามหมายจับ",
      },
    ],
  }),
  created() {
    this.user = JSON.parse(
      Decode.decode(localStorage.getItem("userBebraveWeb"))
    );
    this.getm_group();
    this.getAllData();
  },
  methods: {
    async getm_group() {
      const m_groupItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs`
      );
      this.m_groupItems = m_groupItems.data.data;
      console.log("this.m_groupItems", this.m_groupItems);
    },
    async getm_area(val) {
      console.log("val", val);
      const m_areaItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs/findAreaByGroup?group=${this.m_group}`
      );
      this.m_areaItems = m_areaItems.data.data;
      console.log("this.m_areaItems", this.m_areaItems);
    },
    async getm_province(val) {
      console.log("val", val);
      const m_provinceItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs/findProvinceByArea?area=${this.m_area}`
      );
      this.m_provinceItems = m_provinceItems.data.data;
      console.log("this.m_provinceItems", this.m_provinceItems);
    },
    clear() {
      this.drug_name = "";
      this.date = new Date("2022-01-01").toISOString().substr(0, 10);
      this.date1 = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
        this.m_area = "";
        this.m_group = "";
        this.m_province = "";
      this.getAllData();
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async getAllData() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/reportForms/countByType?type_name=${this.type_name}&fromDate=${this.date}&toDate=${this.date1}&group=${this.m_group}&area=${this.m_area}&province=${this.m_province}`,
        auth
      );
      console.log("getAllbannerpe", response.data.data);
      this.items = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].number = parseInt(i) + 1;
      }

      console.log("items", this.items);
      this.loading = false;
    },
  },
};
</script>

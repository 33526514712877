<template>
  <v-row align="center" justify="center" class="pa-4">
    <loading :active.sync="loading"></loading>
    <v-col>
      <h1 style="font-weight: 800; color: #577eba">เพิ่มจุดเสี่ยง</h1>
    </v-col>
    
    <v-card class="pa-8" width="100%">
      <div style="padding-top: -20px; padding: 30px; ">
                <GmapMap
                  :center="center"
                  :zoom="15"
                  map-style-id="roadmap"
                  :options="mapOptions"
                  style="width: 100%; height: 60vmin"
                  ref="mapRef"
                  @click="handleMapClick"
                >
                  <GmapMarker
                    :position="marker.position"
                    :clickable="true"
                    :draggable="true"
                    @drag="handleMarkerDrag"
                    @click="panToMarker"
                  />
                </GmapMap>
              </div>
              <br>
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อสถานที่</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="riskForm.name"
              label="เช่น ชื่อซอย ชื่ออาคาร"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">รายละเอียด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-textarea
              v-model="riskForm.descriptions"
              placeholder="รายละเอียด"
              solo
              dense
              :rules="rules.required"
            ></v-textarea>
            <!-- <quill-editor
              :content="content"
              :options="editorOption"
              @change="onEditorChange($event)"
            /> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="11"
                  md="11"
                  sm="11"
                  xs="11"
                  class="ma-4"
                  id="step-3"
                >
                  <span>แนบรูปประกอบ</span>
                  <span style="color: red">*</span>
                  <br />
                  <!-- <span style="color: red">ใช้นามสกุล .png ขนาด 300*300 </span> -->
                  <v-img :src="showImage" max-width="auto"></v-img>

                  <v-col cols="12" md="12" class="text-right">
                    <v-file-input
                      v-model="file"
                      accept="image/png, image/jpeg, image/bmp"
                      filled
                      prepend-icon="mdi-camera"
                      solo
                      dense
                    ></v-file-input>
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="primary">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      riskForm: {
        name: "",
        latitude: "",
        longitude: "",
        descriptions: "",
        image_url: "",
        address: "",
      },
      marker: { position: { lat: 13.7245601, lng: 100.4930261 } },
      center: { lat: 13.7245601, lng: 100.4930261 },

      mapOptions: {
        disableDefaultUI: true,
      },
      news_type: "ข่าวเด่น",
      news_title: "",
      content: "<br/><br/><br/><br/><br/>",
      keyImg: "",
      activeFlag: true,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      img: "",
      loading: false,

      loading: false,
      file: [],
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  async created() {
    this.geolocate();
  },
  methods: {
     geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        this.panToMarker();
      });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    //Moves the map view port to marker
    panToMarker() {
      // this.$refs.mapRef.panTo(this.marker.position);
      // this.$refs.mapRef.setZoom(18);
      this.center = this.marker.position;
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageBanners_2");
    },
    async submitfileMain() {
      if (this.file.length !== 0) {
        let formData = new FormData();
        formData.append("uploadFile", this.file);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        // const formData = {
        //   uploadFile: this.file,
        // };
        this.loading = true;
        const response = await this.axios.post(
          `https://thai-drug-service-temp.yuzudigital.com/files/formData`,
          formData
        );
        // .then(function () {
        console.log("SUCCESS!!");
        console.log("response", response);
        this.loading = false;
        if (response.data.response_status == "SUCCESS") {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "success",
            text: "อัพโหลดสำเร็จ",
          });
          this.pic1URL = response.data.data.path;
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "อัพโหลดไฟล์ไม่สำเร็จ",
          });
        }
      }
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        await this.submitfileMain();
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userBebraveWeb"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        // const data = {
        //   news_type: "ข่าวเด่น",
        //   news_title: this.news_title,
        //   description: this.content,
        //   keyImg: this.pic1URL,
        //   active_flag: true,
        // };
        this.riskForm.image_url = this.pic1URL
        this.riskForm.latitude = this.marker.position.lat
        this.riskForm.longitude = this.marker.position.lng

        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/riskPoints`,
          this.riskForm,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างจุดเสี่ยงสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("table1");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
<template>
  <v-row align="center" justify="center" class="pa-4">
    <loading :active.sync="loading"></loading>
    <v-col>
      <h1 style="font-weight: 800; color: #577eba">เพิ่มรูปผู้สนับสนุน</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อรูปผู้สนับสนุน</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="partner_title"
              label="ชื่อรูปผู้สนับสนุน"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- partner_img
partner_link -->
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">link</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="partner_link"
              label="link"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="11"
                  md="11"
                  sm="11"
                  xs="11"
                  class="ma-4"
                  id="step-3"
                >
                  <span>รูปสไลด์</span>
                  <span style="color: red">*</span>
                  <br />
                  <!-- <span style="color: red">ใช้นามสกุล .png ขนาด 300*300 </span> -->
                  <v-img :src="showImage" max-width="auto"></v-img>

                  <v-col cols="12" md="12" class="text-right">
                    <v-file-input
                      v-model="file"
                      accept="image/png, image/jpeg, image/bmp"
                      filled
                      prepend-icon="mdi-camera"
                      solo
                      dense
                    ></v-file-input>
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="primary">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      partner_title: "",
      partner_link: "",
      // bannerDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],

      loading: false,
      file: [],
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  async created() {},
  methods: {
    // async showImg(val) {
    //   this.loading = true;
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/products/` + val
    //   );
    //   console.log("getAllproducts", response.data.data);
    //   this.img = response.data.data.imgUrl;
    //   this.loading = false;
    // },
    async dropdown() {
      this.loading = true;
      const cateDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`
      );
      const typeDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/types`
      );
      const brandDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`
      );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      this.itemsCategory = cateDropdown.data.data;
      this.itemsType = typeDropdown.data.data;
      this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      console.log("companyDropdown", this.itemsCompany);
      this.loading = false;
    },
    async getAllProduct() {
      this.loading = true;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/findAllAdmin?brandId=` +
          this.brand +
          "&typeId=" +
          this.type +
          "&categoryId=" +
          this.category
        // 'http://localhost:8081/types/'
      );
      console.log("getAllproducts", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      this.loading = false;
    },
    async submitfileMain() {
      this.loading = true;
      let formData = new FormData();
      formData.append("uploadFile", this.file);
      console.log("formData", formData);
      const auth = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      // const formData = {
      //   uploadFile: this.file,
      // };
      this.uploaded = true;
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/files/formData`,
        formData
      );
      // .then(function () {
      console.log("SUCCESS!!");
      console.log("response", response);
      this.uploaded = false;
      if (response.data.response_status == "SUCCESS") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "success",
          text: "อัพโหลดสำเร็จ",
        });
        this.pic1URL = response.data.data.path;
        this.loading = false;
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "อัพโหลดไฟล์ไม่สำเร็จ",
        });
        this.loading = false;
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManagePartners");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        await this.submitfileMain();
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userBebraveWeb"))
        );
        // const auth = {
        //   headers: { Authorization: `Bearer ${this.user.token}` },
        // };
        const data = {
          partner_img: this.pic1URL,
          partner_title: this.partner_title,
          partner_link: this.partner_link,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/partners`,
          data
          // auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างรูปผู้สนับสนุนสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManagePartners");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>